import * as React from "react"

function getDarkness(c) {
    var c = c.substring(1);      // strip #
    var rgb = parseInt(c, 16);   // convert rrggbb to decimal
    var r = (rgb >> 16) & 0xff;  // extract red
    var g = (rgb >> 8) & 0xff;  // extract green
    var b = (rgb >> 0) & 0xff;  // extract blue

    return 0.2126 * r + 0.7152 * g + 0.0722 * b; // per ITU-R BT.709
}

export default function LanguageImage({ lang, color, full }) {

    return <svg style={{
        "-webkit-user-select": "none",
        "-moz-user-select": "none",
        "-ms-user-select": "none",
        "user-select": "none",
        "pointer-events": "none",
        " -webkit-tap-highlight-color": "rgba(255, 255, 255, 0)"
    }} alt={`The Irishman Icon for the ${full} Programming Language.`} viewBox="0 0 200 200" xmlns="http://www.w3.org/2000/svg">
        <rect fill={color} x="0" y="0" width="200" height="200" />
        <style dangerouslySetInnerHTML={{ __html: `.langSvgText { font-size: 100px; font-weight: 700; } .langSvgSmallText { font-size: 50px; font-weight: 700; }` }} />
        <text fill={getDarkness(color) < 200 ? '#ffffff' : '#333333'} text-anchor="end" x="190" y="185" class={lang.length < 3 ? 'langSvgText' : 'langSvgSmallText'}>{lang.toUpperCase()}</text>
    </svg>;

}