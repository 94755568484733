import * as React from "react"
import { Link } from "gatsby"
import { StaticImage } from "gatsby-plugin-image"
// install (please try to align the version of installed @nivo packages)
// yarn add @nivo/line
import {
    Chart as ChartJS,
    CategoryScale,
    LinearScale,
    PointElement,
    LineElement,
    Title,
    Tooltip,
    Legend,
  } from 'chart.js';
import { Line } from 'react-chartjs-2';

import Layout from "../components/layout"
import Seo from "../components/seo"

import Chart from "../static/chart.svg"
import PriceChart from "../components/priceChart"
import LanguageImage from "../components/languageImage";

function pickTextColorBasedOnBgColorSimple(bgColor, lightColor, darkColor) {
    var color = (bgColor.charAt(0) === '#') ? bgColor.substring(1, 7) : bgColor;
    var r = parseInt(color.substring(0, 2), 16); // hexToR
    var g = parseInt(color.substring(2, 4), 16); // hexToG
    var b = parseInt(color.substring(4, 6), 16); // hexToB
    return (((r * 0.299) + (g * 0.587) + (b * 0.114)) > 186) ?
      darkColor : lightColor;
  }

const IndexPage = ({ pageContext }) => {

    let { name, short, equation, units, type, languages, fullDescription, minUnit, xLabel, pricePerUnit, logo, languagesDescription, pricingDescription } = pageContext;
    const languagesData = require('../../data/languages.json');
    const categories = require('../../data/categories.json');

    units = units || []

    const primaryUnit = (units || []).filter(i => i.primary)[0] || (units || [])[0] || {}

    ChartJS.register(
        CategoryScale,
        LinearScale,
        PointElement,
        LineElement,
        Title,
        Tooltip,
        Legend
      );

    let labels = [];
    let data = [];

    for (let n = 0; n < 100; n++) {

        const output = new Function([ ...units.map(i => i.name), "max", "min"], `"use strict";return (${equation});`)( ...units.map(i => n * (primaryUnit == i ? 1 : primaryUnit.scale) * i.scale), Math.max, Math.min);

        labels.push(n * primaryUnit.scale);
        data.push(output);

        console.log(output);

    }

    return <Layout>
        <section style={{ background: categories[type].color, color: pickTextColorBasedOnBgColorSimple(categories[type].color, '#ffffff', '#000000' ) }} id="features">
            <div class="mx-auto max-w-screen-xl px-4 py-16 sm:px-6 lg:px-8 flex flex-row">
                <div class="">
                    <h1 class="text-3xl font-bold sm:text-4xl">{name}</h1>
                    <p class="mt-4">
                        {short}
                    </p>
                </div>
            </div>
        </section>
        <section class="mt-16" id="features">
            <div class="mx-auto max-w-screen-xl px-4 py-16 sm:px-6 lg:px-8">
                <div
                    class="grid grid-cols-1 gap-y-8 py-8 lg:grid-cols-2 lg:items-center lg:gap-x-16"
                >
                    <div class="mx-auto h-full max-w-lg lg:mx-0 lg:text-left flex align-top flex-col">
                        <h1 class="text-3xl font-bold sm:text-3xl">What is {name}?</h1>
                        <p class="text-gray-600 text-left" dangerouslySetInnerHTML={{ __html: fullDescription.replace(/<p>/g, '<p class="mt-4">') }} />
                    </div>
                </div>
            </div>
        </section>

        <section class="mt-16" id="features">
            <div class="mx-auto max-w-screen-xl px-4 py-16 sm:px-6 lg:px-8">
                <div
                    class="grid grid-cols-1 gap-y-8 py-8 lg:grid-cols-2 lg:items-center lg:gap-x-16"
                >
                    <div>
                        <Line
                            options={{
                                scales: {
                                    y: {
                                        ticks: {
                                            // Include a dollar sign in the ticks
                                            callback: function(value, index, ticks) {
                                                return '$' + value.toFixed(2);
                                            }
                                        }
                                    }
                                }
                            }}
                            color={categories[type].color}
                            data={{
                                labels,
                                datasets: [{
                                  label: `Price due to ${primaryUnit.text}`,
                                  data,
                                  fill: false,
                                  borderColor: categories[type].color,
                                  tension: 0.1
                                }],
                              }}
                        />
                    </div>
                    <div class="mx-auto h-full max-w-lg lg:mx-0 lg:text-left flex align-top flex-col">
                        <h2 class="text-3xl font-bold sm:text-3xl">Pricing for {name}</h2>
                        <p class="text-gray-600" dangerouslySetInnerHTML={{ __html: pricingDescription.replace(/<p>/g, '<p class="mt-4">') }} />
                    </div>
                </div>
            </div>
        </section>

        <section class="mt-16" id="features">
            <div class="mx-auto max-w-screen-xl px-4 py-16 sm:px-6 lg:px-8">
                <div
                    class="grid grid-cols-1 gap-y-8 py-8 lg:grid-cols-2 lg:items-center lg:gap-x-16"
                >
                    <div class="mx-auto h-full max-w-lg lg:mx-0 lg:text-left flex align-top flex-col">
                        <h2 class="text-3xl font-bold sm:text-3xl">{name}'s Language Support</h2>
                        <p class="text-gray-600" dangerouslySetInnerHTML={{ __html: languagesDescription.replace(/<p>/g, '<p class="mt-4">') }} />
                        <p class="mt-4 text-gray-600">
                            {name} supports about {languages.length} different programming languages. This list includes {new Intl.ListFormat('en-US', { style: 'long', type: 'conjunction' }).format(languages)}.
                        </p>
                    </div>
                    <div class="w-full p-4 grid grid-cols-2 gap-4 sm:grid-cols-4">
                        {
                            languages.map((language) => {

                                // return <div class="block"><img class="w-full h-full" alt={`The ${language} Programming Language Logo and/or Icon`} src={languagesData[language].icon} /></div>
                                return <LanguageImage lang={languagesData[language].lang} color={languagesData[language].color} full={language} />

                            })
                        }
                    </div>
                </div>
            </div>
        </section>
        <section class="h-50 w-full p-16"></section>
    </Layout>
}

export const Head = ({ pageContext }) => <Seo title={pageContext.name} children={<>
    <script type="application/ld+json" dangerouslySetInnerHTML={{
        __html: JSON.stringify({
            "@context": "https://schema.org",
            "@type": "FAQPage",
            "mainEntity": [{
                "@type": "Question",
                "name": `What is Irishman ${pageContext.name}?`,
                "acceptedAnswer": {
                    "@type": "Answer",
                    "text": pageContext.fullDescription
                }
            }, {
                "@type": "Question",
                "name": `How much does Irishman ${pageContext.name} cost?`,
                "acceptedAnswer": {
                    "@type": "Answer",
                    "text": pageContext.pricingDescription
                }
            }, {
                "@type": "Question",
                "name": `What languages does Irishman ${pageContext.name} support?`,
                "acceptedAnswer": {
                    "@type": "Answer",
                    "text": `${pageContext.name} supports about ${pageContext.languages.length} different programming languages. This list includes ${new Intl.ListFormat('en-US', { style: 'long', type: 'conjunction' }).format(pageContext.languages)}.`
                }
            }]
        })
    }} />
</>} />

export default IndexPage
